<template>
    <div class="admin_page_body">
        <div class="legend_main">
            <h1 class="title">说明</h1>
            <p class="text"> · 商品规格只能添加3个（包括平台预设规则），每个规格只能添加10个规格值。</p>
            <p class="text"> · 平台设置的规格不能编辑操作，但可以在店铺商品发布时选择。</p>
        </div>

        <div class="admin_page_item pr0 mt18">
            <div class="data_group">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="isPopupAdd = true">添加规格</el-button>
            </div>


            <div class="data_table mt18">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column prop="name" label="规格" min-width="140px"></el-table-column>
                    <el-table-column prop="value" label="规格值" min-width="200px"></el-table-column>
                    <el-table-column label="操作" align="center" width="100">
                        <template slot-scope="scope">
                            <template v-if="scope.row.type === 1">——</template>
                            <el-button type="text" @click="toEditFormat(scope.row)" v-if="scope.row.type === 2">编辑</el-button>
                            <el-button type="text" @click="delData(scope.row.id)" v-if="scope.row.type === 2">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog class="popup_dialog" title="添加规格" :visible.sync="isPopupAdd" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formAdd" :model="formAdd" size="small" label-position="top" :rules="rules">
                <el-form-item label="规格名称：" prop="name">
                    <el-input v-model="formAdd.name" autocomplete="off" placeholder="请输入规格名称"></el-input>
                </el-form-item>

                <el-form-item label-width="0" prop="value">
                    <el-table :data="formAdd.value" style="width: 100%">
                        <el-table-column prop="address" label="规格值">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.name" placeholder="请输入规格值"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作" align="center" width="60">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="scope.$index === 0" @click="formAddToAddFormat">添加</el-button>
                                <el-button type="text" v-else @click="formAddToDelFormat(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="submitFormAdd('formAdd')">提  交</el-button>
                    <el-button @click="isPopupAdd = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog class="popup_dialog" title="编辑规格" :visible.sync="isPopupEdit" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formEdit" :model="formEdit" label-width="80px" size="small" :rules="rules">
                <el-form-item label="规格名称：" prop="name">
                    <el-input v-model="formEdit.name" autocomplete="off" placeholder="请输入规格名称"></el-input>
                </el-form-item>

                <el-form-item label-width="0" prop="value">
                    <el-table :data="formEdit.value" style="width: 100%">
                        <el-table-column prop="address" label="规格值">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.name" placeholder="请输入规格值"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作" align="center" width="60">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="scope.$index === 0" @click="formEditToAddFormat">添加</el-button>
                                <el-button type="text" v-else @click="formEditToDelFormat(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="submitFormEdit('formEdit')">保 存</el-button>
                    <el-button @click="isPopupEdit = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
  import {delGoodsFormat, getGoodsFormat, postGoodsFormat, putGoodsFormat} from "../../../api/admin/goods";
  export default {
    name: "adminGoodsFormat",
    data () {
      return {
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        isPopupAdd: false,
        formAdd: {
          name: '',
          value: [
            {
              name: ''
            }
          ]
        },
        rules: {
          name: [
            {required: true, message: '请输入规格名称', trigger: 'blur'}
          ],
        },
        isSubmitLoading: false,
        isPopupEdit: false,
        formEdit: {
          name: '',
          value: [
            {
              name: ''
            }
          ]
        },
        editId: ''
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getList()
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getGoodsFormat().then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      formAddToAddFormat () {
        this.formAdd.value.push({
          name: ''
        })
      },
      formAddToDelFormat (index) {
        this.$confirm('是否确认删除该规格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          this.formAdd.value.splice(index, 1)
        }).catch(() => {});
      },
      submitFormAdd (formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let value = ''
            let isError = false
            this.formAdd.value.forEach((item, index) => {
              if (item.name === '') {
                isError = true
              }
              if (Number(index) === 0) {
                value = item.name
              } else {
                value = value + ',' + item.name
              }
            })

            if (isError) {
              this.$message({
                message: '请输入规格值',
                type: 'warning',
                showClose: true,
              });
              return false
            }

            this.isSubmitLoading = true
            postGoodsFormat({
              data: {
                name: this.formAdd.name,
                value: value
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '规格添加成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupAdd = false
                this.$refs[formName].resetFields()
                this.formAdd.value = [
                  {
                    name: ''
                  }
                ]
                this.getList()
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          }
        })
      },
      toEditFormat (item) {
        this.editId = item.id
        this.formEdit.name = item.name
        let value = []
        item.value.split(',').forEach(item => {
          value.push({
            name: item
          })
        })
        this.formEdit.value = value
        this.isPopupEdit = true
      },
      formEditToAddFormat () {
        this.formEdit.value.push({
          name: ''
        })
      },
      formEditToDelFormat (index) {
        this.$confirm('是否确认删除该规格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          this.formEdit.value.splice(index, 1)
        }).catch(() => {});
      },
      submitFormEdit(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let value = ''
            let isError = false
            this.formEdit.value.forEach((item, index) => {
              if (item.name === '') {
                isError = true
              }
              if (Number(index) === 0) {
                value = item.name
              } else {
                value = value + ',' + item.name
              }
            })

            if (isError) {
              this.$message({
                message: '请输入规格值',
                type: 'warning',
                showClose: true,
              });
              return false
            }

            this.isSubmitLoading = true
            putGoodsFormat(this.editId, {
              data: {
                name: this.formEdit.name,
                value: value
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '规格编辑成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupEdit = false
                this.getList()
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          }
        })
      },
      delData (id) {
        this.$confirm('此操作将永久删除该规格, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delGoodsFormat(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '规格删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {}
  }
</script>
