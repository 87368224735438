import {request} from '@/utils/request'

// 规格列表
export function getGoodsFormat(data) {
  return request('GET','/shop/specs', data, true)
}

// 添加规格
export function postGoodsFormat(data) {
  return request('POST','/shop/specs', data, true)
}

// 编辑规格
export function putGoodsFormat(id, data) {
  return request('PUT','/shop/specs/' + id, data, true)
}

// 删除规格
export function delGoodsFormat(id) {
  return request('DELETE','/shop/specs/' + id, '', true)
}

// 获取类目
export function getMerchantClass(level, pid) {
  return request('POST','/shop/corporate/getCorporateToPid/' + level + '/' + pid, '', true)
}

// 获取属性
export function getClassAttr(id) {
  return request('GET','/category/' + id, '', true)
}

// 获取商品
export function getGoods(data) {
  return request('GET','/shop/goods', data, true)
}

// 发布商品
export function postGoods(data) {
  return request('POST','/shop/goods', data, true)
}

// 修改商品
export function putGoods(id, data) {
  return request('PUT','/shop/goods/' + id, data, true)
}

// 商家编码验证
export function getGoodsSkuCode(code) {
  return request('GET','/shop/goods/skuCodeVerify/' + code, '', true)
}

// 获取商品
export function getGoodsInfo(id) {
  return request('GET','/shop/goods/' + id, '', true)
}

// 批量操作
export function postGoodsBatch(data) {
  return request('POST','/shop/goods/batch', data, true)
}

// 删除商品
export function delGoods(id) {
  return request('DELETE','/shop/goods/' + id, '', true)
}
